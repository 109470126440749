$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Logo
//


.c-logo {
	$c: &;

	display: block;
	width: grid-baseline(15);

	//	Elements   ------------------

	svg {
		width: auto; height: 100%; max-width: 100%;
		display: block;
	}

	//	Styles	 --------------------

	&:global(.-style-white) {
		@include color-set( --primary, white );
		@include color-set( --text, white );
		color: color-get( white );
	}

	//	Sizes	 --------------------

	&.-size-s {
		width: grid-baseline(10);
	}

	&.-size-l {
		width: grid-baseline(20);
	}
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {
	.c-logo {
		$c: &;

		width: grid-baseline(18);
	}
}
