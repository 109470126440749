$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Marquee
//


@keyframes scroll {
	0% { transform: translateX(0%); }
	100% { transform: translateX(-100%); }
}

.c-marquee {
	$c: &;

	position: relative;
	display: flex; align-items: center;
	width: 100vw;
	max-width: 100%;
	height: grid-baseline(10);
	overflow-x: hidden;
	background-color: color-get( --bg );
	color: color-get( --text );

	// Elements

	&_container {
		overflow-x: hidden;
		display: flex;
		flex-direction: row;
		position: relative;
		width: 100%;
	}

	&_content {
		flex: 0 0 auto;
		gap: grid-baseline(5);
		z-index: 1;
		display: flex;
		flex-direction: row;
		align-items: center;
		animation: scroll  #{ var( --marquee-duration )} linear 0s infinite;
		animation-play-state: running;
		animation-delay: 0s;
		animation-direction: normal;

		> div {
			display: flex;
			align-items: center;

			&:last-child {
				margin-right: grid-baseline(5);
			}
		}
	}

	// Sizes
	&.-size-s {
		height: grid-baseline(4);

		#{ $c }_content {
			gap: grid-baseline(2);

			> div:last-child {
				margin-right: grid-baseline(2);
			}
		}
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {
	.c-marquee {
		$c: &;
		height: grid-baseline(13);

		// Sizes
		&.-size-s {
			height: grid-baseline(5.5);
		}
	}
}
