$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / App / Layout
//


.a-layout {
	$a: &;

	background-color: color-get( --bg );

	&_main {
		position: relative; z-index: 10;
	}

	&_canvas {
		height: 100vh;
		position: fixed; inset: 0; bottom: auto; z-index: 0;
	}
}
