$env: production;
.key-forge-master-steps {

  &-item {
    &-icon {
      top: -20px;
    
      svg{
        width:90px;
        height:90px;
      }
      @media (max-width: 900px) {
        right:20%;
      } 
       @media (max-width: 500px) {
        right:10%;
      }
    }

    &-title {
      background: linear-gradient(180deg, #dc77ff 0%, #7f74ff 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }
  
}
// end of master steps
