$env: production;
@use "src/styles/base/mixins.scss";

.home-tab {
  &-header {
    @include mixins.neonColoredText;

    &-icon {
      svg {
        fill: #c0c0c0;
      }
    }
  }

  &-do-your-stuff {
    &-cards {
      &-item {
        width: 31%;

        @media (max-width: 800px) {
          width: 47.5%;
        }

        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }
  }

  &-safety-tips {
    &-cta-bar {
      &-middle {
        width: 47%;
        font-size: 14px;
        color: #c0c0c0;
        font-weight: 400;
        margin: 0 20px;

        @media (max-width: 1000px) {
          width: auto;
          margin: 20px 0;
        }
      }

      &-right {
        width: 23%;

        &-button {
          color: #b3fe00;
          font-size: 11px;
          border: 1px solid #b3fe00;

          &:hover {
            color: #b3fe00;
          }
        }

        @media (max-width: 1000px) {
          width: auto;
          margin: 20px 0;
        }
      }
    }
  }
}