$env: production;
@use 'src/styles/base/mixins.scss';

.tab-title{
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    font-size: 64px;
    line-height: 110%;
    @include mixins.neonColoredText;
    margin: 0;
    padding: 0;
    text-transform: uppercase;
    
    @media(max-width:500px){
        font-size: 46px;
     }
     @media(max-width:400px){
        font-size: 36px;
     }
}