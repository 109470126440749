$env: production;
.action-button {
  /* Gradient */
  color:black;
  font-size: 20px;
  line-height: 100%;
  background: linear-gradient(266.74deg, #b3fe00 45.07%, #00f644 97.3%);
  border-radius: 30px;
  padding:12px 18px;

  &-text{
    margin-top:1%;
  }
  &-disabled{
    opacity: 0.5;
    cursor: not-allowed;
  }
}
