$env: production;
.text-settings {
  width: auto;
  height: auto;
  background: #282828;
  padding: 8px;
  border-radius: 14px;
  position: absolute !important;
  right: 0%;
  margin-top: -20px;
  color: white;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  padding: 10px 30px;
  display:flex;
  justify-content: center;

  &-font-size-label {
    margin-left: 5px;
  }
  &-font-size-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &-font-size-dropdown {
    color: inherit;
    font-size: inherit;
    font-weight: inherit;
    font-style: inherit;
    width: 140px;
    padding: 12px 12px 12px 16px;
    align-items: center;
    border-radius: 8px;
    border: 0px solid var(--grey, #c0c0c0);
    background: rgba(0, 0, 0, 0.4);
    margin: 8px 0;

    &-expanded {
      width: 120px;
    }

    & > option {
      color: white;
      width: 100%;
      padding: 12px 12px 12px 16px;
      align-items: center;
      border-radius: 8px;
      border: 0px solid var(--grey, #c0c0c0);
      background: rgba(0, 0, 0, 0.4);
    }
  }
}
