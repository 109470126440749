$env: production;
.key-forge-animation {
  position: fixed;
  top: 10.75rem; //to counter push down effect for odyssey pages
  z-index: 9999;
  background-color: color-get(--bg);
  height: 100vh;
  width: 100vw;
  video {
    width: 100vw;
    object-fit: contain;
  }
}
