$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Headline
//


.c-headline {
	$c: &;

	width: 100%;
	display: block;

	// Elements

	&_text {
		margin-bottom: -25%;
		display: inline-block;
		white-space: pre-wrap;
		line-height: 1.5; vertical-align: top;
		overflow: hidden;
		transform: translate3d(0,-25%,0);
		transform-origin: 50% 50%;
		will-change: opacity;

		> div {
			transform: translate3d(0,0,0);
			display: inline-block;
			will-change: transform;
		}
	}

	// Styles

	&:global(.-style-underline) {
		position: relative;
		width: max-content;

		&::after {
			content: '';
			position: absolute;
			bottom: grid-baseline(-1); right: 0; left: 0;
			width: 100%;
			border-bottom: 4px solid color-get( --headline );
			margin-right: auto; margin-left: auto;
		}
	}

	&:global(.-style-primary-gradient) {

		#{ $c }_text {

			> div {
				background-color: color-get( --primary );
				background: linear-gradient(266.74deg, color-get( --primary ) 45.07%, color-get( --primary-mid ) 97.3%);
				background-size: 100%;
				-webkit-background-clip: text;
				-moz-background-clip: text;
				-webkit-text-fill-color: transparent;
				-moz-text-fill-color: transparent;
			}
		}
	}
}
