@mixin neonColoredText {
  background: linear-gradient(266.74deg, #b3fe00 45.07%, #00f644 97.3%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin centerColumnVertically {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@mixin centerRowVertically {
  display: flex;
  align-items: center;
}
