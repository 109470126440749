$env: production;
@use 'styles/modules' as *;

//
//	RAYC / UI / App / Menu
//

.a-menu {
  $a: &;

  &_link {
    background: linear-gradient(266.74deg, #b3fe00 45.07%, #00f644 97.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &:global(.-is-active) {
    pointer-events: auto;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &_container {
    padding: grid-baseline(15) 0 grid-baseline(3);
    color: color-get(--text);
    background-color: color-get(--bg, 0.6);
    backdrop-filter: blur(40px);
    border-bottom: 1px solid color-get(grey-40, 0.2);
    overflow: hidden;

    > div {
      height: 100%;
    }
  }

  &_nav {
    gap: grid-baseline(2);
    margin-top: grid-baseline(2);


    &-list {
      align-items: center;
      gap: 0;
    }
    & > * {
      margin-bottom: 1rem;
    }
  }

  &_serum-zombie {
    display: flex;
    flex-direction: column;
    gap: grid-baseline(2);
    margin-top: grid-baseline(2);

    & > * {
      margin-bottom: 1rem;
    }
  }

  &_socials {
    display: flex;
    gap: grid-baseline(2);
    margin-top: grid-baseline(8);
  }

  &_overlay {
    position: absolute;
    inset: 0;
  }
}
