$env: production;
@use 'src/styles/base/mixins.scss';

.meme-generator{

    &-template-container{
        margin:32px 0;
        width:100%;
        height: auto;
        display: grid;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 160px);
        gap: 16px;
     
        @media(min-width:2000px){
            grid-template-columns: repeat(7, 1fr);
        }
        @media(max-width:1200px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media(max-width:1000px){
            grid-template-columns: repeat(3, 1fr);
        }
        @media(max-width:900px){
            grid-template-columns: repeat(4, 1fr);
        }
        @media(max-width:800px){
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
        }
        @media(max-width:550px){
            grid-template-columns: repeat(2, 1fr);
            gap: 8px;
        }
  
    }

    &-title{
        @include mixins.neonColoredText;
        @include mixins.centerRowVertically;
        font-weight: 700;
        font-size: 18px;
        line-height: 86.18%;
        margin-bottom: 36px;
        text-transform: uppercase;
        &-icon{
          svg{
              fill: #c0c0c0;
              margin-right:8px;
          }
        }
    }
}