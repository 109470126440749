$env: production;
.meme-modal-container {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display:flex;
  align-items:center;
  justify-content: center;
}

.meme-modal-overlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
}

.meme-modal-content {
  display: flex;
  width: 488px;
  height: auto;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  position:absolute;
  border-radius: 24px;
  border: 1px solid #FFF;
  background: #151515;

  &-close-icon{
    margin-top:2%;
    cursor: pointer;
  }
  &-title{
    text-transform:uppercase;
    margin:0;
    padding:0;
    display:flex;
    justify-content:space-between;
    align-items:flex-start;
    width:100%;
  }
  &-socials{
    &-header{
        color:white;
    }
    &-items{
        width:100%;
        height:auto;
        display:flex;
        justify-content:flex-start;
        margin-top:16px;
    }
    &-item{
        cursor: pointer;
        margin:0;
        padding:0;
        align-self:flex-start;
    }
  }

  &-meme-image{
    width: auto;
    object-fit: fill;
  }

  &-buttons{
    display:flex;
    width: 100%;
    justify-content:space-between;
    align-items:center;

    &-done,&-download{
        width:47.5%;
    }
    &-download{
        color:white;
        border:1px solid white;
        background:black;
    }
    @media(max-width:500px){
        flex-direction:column;
        &-done,&-download{
            width:100%;
        }
        &-download{
         margin-bottom:14px;
        }
    }
  }

  @media (max-width:600px) {
    box-sizing: border-box;
    width: 90%;
    height: auto;
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  @media (max-width:400px) {
    padding: 16px;
  }
}