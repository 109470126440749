$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / App / Page
//


.a-view {
	$a: &;

	flex: 1;
}
