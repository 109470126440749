@use 'styles/modules' as *;


//
//	RAYC / Styles / Base / Init
//


html, body {
	height: 100%;
	font-weight: var( --font-weight-regular );
	color: color-get( --text );
}

body {
	color: color-get( --text );
	background-color: color-get( --bg );
}

h1, h2, h3, h4, h5, h6 {
	font-weight: var( --font-weight-bold );
	color: color-get( --headline );
	transition: color 0.2s;
}

p {
	color: color-get( --text );
}

table {
	color: color-get( --text );
}

a {
	color: color-get( --link );
	text-decoration: none;
}