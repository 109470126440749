$env: production;
$break-point-big: 900px;
$break-point-medium: 750px;
$break-point-small: 500px;


.key-forge {
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;


  // key image on top key forge header
  &-img {
    &-key {
      position: absolute;
      top: -8%;
      left: -7%;
      @media (max-width: $break-point-big) {
        width: 70px;
        height: 85px;
        left: 30%;
        top: 0%;
      }
    }
  }

  // linear gradient based header
  &-header {
    // couldnt achieve same result with tailwind 

    /* Trifle */
    text-align: center;
    background: linear-gradient(
      271.23deg,
      #60ffcf 19.94%,
      #ffffff 63.65%,
      #5019d7 103.04%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-transform: uppercase;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  // start of transparent box
  &-transparent-box {
    backdrop-filter: blur(50px); // couldnt achieve same result with tailwind
    /* Note: backdrop-filter has minimal browser support */
  }
  // end of transparent box
}
