$env: production;
.meme-template-editor {
  width: 100%;
  max-width: 1000px;
  min-height: 485px;

  &-color-picker{
    position:absolute !important;
    right:0;
    margin-top:-20px;
  }

  &-button{
    &-add{
        color:white;
        background: black ;
        border:1px solid white;
        margin-top: 12px;
        margin-bottom:24px;
    }
    &-save{
        width:216px;
        font-size: 32px;
    }

  }

  &-controller {
    display: flex;
    justify-content: space-between;
    @media (max-width:1200px) {
      flex-direction: column;
    }
  }
  &-image-container {
    position:relative;
    width: auto;
    height: auto;

    &-texts{
      font-size: 60px;
      position:absolute;
      top:0%;
      left:0%;
      width:100%;
      height:100%;
      z-index:2;
      overflow: hidden;
      &-item{
        cursor:pointer;
        font-size:20px;
        line-height: 120%;
        font-family: Impact;
      }
    }
    @media (max-width:1200px) {
      margin-bottom:15px;
    }
    @media (max-width:575px) {
      width:auto;
      // height:275px;
    }
  }
  
  &-image {
    // has to be specified to enusre text position persist in modal and on image generation
    width: auto;
    height: auto;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: left;
    object-fit: fill;
    margin: 0;
    padding: 0;
    z-index:1;
    display:flex;
    
    &-clone{
      display: inline-block;
      visibility:none;
      opacity: 0;
    }
  }

  &-text-container {
    position: relative;
    width: 450px;
    height: auto;
    margin-left:1.5vw;
    &-title {
      font-family: "Poppins", sans-serif;
      font-size: 18px;
      font-family: Poppins;
      line-height: 20.5px;
    }
    &-text-box {
      box-sizing: border-box;
      width: 100%;
      padding: 4px 24px 16px 24px;
      height: 56px;
      border-radius: 14px;
      border: 1px solid #212121;
      background: #141414;
      position: relative;

      &:not(:last-of-type){
        margin-bottom: 16px;
      }

      &-centered {
        width:100%;
        display: flex;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }

      &-input,
      &-input:active,
      &-input:focus,
      &-input:focus-visible {
        display: inline-block;
        width: 75%;
        box-sizing: border-box;
        color: white;
        padding-bottom: 4px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #c0c0c0;
        outline: none;
        @media (max-width:575px) {
          width: 70%;
        }
      }
      &-controller {
        margin-left: 24px;
        &>*{
            cursor:pointer;
        }
        @media (max-width:575px) {
          margin-left: 14px;
        }
        @media (max-width:350px) {
          margin-left: 8px;
        }
      }

      &-color-selector {
        display: inline-block;
        margin-right: 8px;
        width: 24px;
        height: 24px;
        border: 1px solid #fff;
        @media (max-width:575px) {
          width: 18px;
          height: 18px;
        }
      }
      &-settings{
        @media (max-width:575px) {
          width: 18px;
          height: 18px;
        }
      }
    }
    @media (max-width:575px) {
      width:100%;
    }
  }

}
