$env: production;
.address-box {
    position: relative;
    background-image: none;
    width: auto;
    height: 44px;
    padding: 3px 16px 3px 16px;
    border-radius: 4px;
    background: rgba(100, 100, 100, 0.15);
    border: 1px solid #5b5b5b;
    color: #fff;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    z-index: 10;

    &-icon {
        &-reversed {
            transform: rotate(180deg);
        }
    }

    &-green-circle {
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: #b3fe00;
        margin-left: 16px;
        margin-right: 8px;
        visibility: hidden;
        transition: 0.2s ease-in-out forwards;
    }

    &:hover &-green-circle {
        visibility: visible;
    }

    &-text {
        margin-right: 16px;
    }

    &-variation-drop-down {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        background: #1a1d25;
        &-logout {
            cursor: pointer;
        }

        &-icon {
            margin-right: 8px;
        }

        &:first-of-type {
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }

        &:last-of-type {
            border-bottom-right-radius: 4px;
            border-bottom-left-radius: 4px;
        }

    }
}

.address-drop-down {
    width: 100%;
    height: auto;
    position: absolute;
    top: 120%;
    left: 0;
}