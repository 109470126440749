$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Link
//


.c-link {
	$c: &;

	position: relative;
	cursor: pointer;
	text-decoration: none;
	color: color-get( --link );
	transition: color 0.3s;

	&_button {
		background-color: transparent; border: none;
		-webkit-appearance: none; cursor: pointer;
	}

	//	Styles	 --------------------

	&:global(.-style-text) {
		@include color-set( --link, --text );
		@include color-set( --link-hover, --primary );
	}

	&:global(.-style-headline) {
		@include color-set( --link, --headline );
		@include color-set( --link-hover, --primary );
	}


	//	States	 --------------------
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

@media (hover: hover) {

	.c-link {
		&:hover {
			color: color-get( --link-hover );
		}
	}
}
