@use 'styles/modules' as *;


//
//	RAYC / Styles / Fonts
//



@include type-font( 'burbank', '/fonts/burbank-big-regular', bold, normal );
@include type-font( 'chauncy', '/fonts/chauncy-pro-bold', bold, normal );

/* cyrillic-ext */
@font-face {
	font-family: 'Press Start 2P';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK3nYivNm4I81PZQ.woff2) format('woff2');
	unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }
  /* cyrillic */
  @font-face {
	font-family: 'Press Start 2P';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK3nRivNm4I81PZQ.woff2) format('woff2');
	unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }
  /* greek */
  @font-face {
	font-family: 'Press Start 2P';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK3nWivNm4I81PZQ.woff2) format('woff2');
	unicode-range: U+0370-03FF;
  }
  /* latin-ext */
  @font-face {
	font-family: 'Press Start 2P';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK3nbivNm4I81PZQ.woff2) format('woff2');
	unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
  }
  /* latin */
  @font-face {
	font-family: 'Press Start 2P';
	font-style: normal;
	font-weight: 400;
	src: url(https://fonts.gstatic.com/s/pressstart2p/v14/e3t4euO8T-267oIAQAu6jDQyK3nVivNm4I81.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }
  @font-face {
	font-family: "CraftBrew";
	font-style: normal;
	font-weight: 400;
	src: url("/fonts/HCraftBrew-Regular.otf") format("otf"),
		url("/fonts/HCraftBrew-Regular.ttf") format("truetype");
   }

body {
	font-family: 'Poppins', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
	@include type-family( 'burbank' );
	--font-weight-bold: 700;
}

.-f-family-rubik {
	--font-weight-bold: 400;
	font-family: 'Poppins', sans-serif;
}

.-f-family-burbank {
	--font-weight-bold: 700;
	@include type-family( 'burbank' );
}

.-f-family-chauncy {
	--font-weight-bold: 700;
	@include type-family( 'chauncy' );
}

.f-family-craftbrew{
	--font-weight-bold: 700;
    font-family: 'CraftBrew', cursive;
}

.-f-family-press {
    --font-weight-bold: 400;
    font-family: 'Press Start 2P', cursive;
}