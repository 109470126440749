$env: production;
@use 'styles/modules' as *;
@use 'sass:math';

//
//	RAYC / UI / Components / Button
//

$easing: cubic-bezier(0.4, 0, 0, 1);
$box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0.2);

.c-button {
  $c: &;
  $button-height: type-size-responsive(55, 96);

  @include type-family("burbank");
  font-size: type-size-responsive(25, 56);
  text-transform: uppercase;
  width: auto;
  height: $button-height;
  padding: grid-baseline(2) grid-gutter(1.5) grid-baseline(1) grid-gutter(1);
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: color-get(--button-text);
  font-weight: 500;
  box-shadow: none;
  transform: translate3d(0, 0, 0);
  transition: color 0.3s, background-color 0.3s, border 0.3s, box-shadow 0.3s,
    transform 0.3s;
  transition-timing-function: $easing;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;

  background-image: url("/images/button-bg.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;

  &:not(.-style-none) {
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 5;
      background: color-get(black, 0.25);
      opacity: 0;
      transition: opacity 0.3s $easing;
    }
  }

  //	Elements   ------------------

  &-group {
    display: grid;
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    grid-gap: grid-baseline(1.25);
  }

  &_content {
    height: 100%;
    width: 100%;
    max-width: 100%;
    padding: 0 grid-baseline(2);
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 10;
    transition: opacity 0.15s $easing;

    > span {
      margin: 0 grid-baseline(0.5);

      + #{ $c }_icon {
        padding-left: grid-gutter(1);
        margin-left: auto;
      }
    }

    #{ $c }_icon {
      margin-top: -2.5%; // help alignment with text due to extra spacing in typography
    }
  }
  &_text {
    display: flex;
    white-space: nowrap;
  }

  //	Styles	 --------------------

  &:global(.-style-none) {
    @include color-set(--button-text, --base);
    background: transparent;
    border: none;
    padding: grid-baseline(1);
  }

  &:global(.-style-round) {
    font-family: "Poppins", sans-serif;
    background-image: none;
    color: color-get(--primary);
    border: 1px solid color-get(--primary);
    border-radius: 30px;
    text-transform: initial;
    width: auto;
    height: auto;

    //	Sizes	 --------------------

    &.-size-s {
      font-size: type-size-responsive(12, 16);
      padding: grid-baseline(0.75) 0;

      #{ $c }_content {
        padding: 0 grid-baseline(1.25);
      }
    }
  }

  //	Formats	 --------------------

  &.-format-icon {
    width: $button-height;
    height: $button-height;
    flex-shrink: 0;

    #{$c}_content {
      padding-left: 0;
      padding-right: 0;
    }
    .c-icon {
      margin: 0;
    }
  }

  //	States	 --------------------

  &:active {
    #{$c}_content {
      opacity: 0.4;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */

@media (hover: hover) {
  .c-button:not(.-is-disabled) {
    &:hover {
      box-shadow: $box-shadow;
      transform: translate3d(0, -1px, 0);

      &:after {
        opacity: 1;
      }
    }

    &:global(.-style-none):hover {
      color: color-get(--button-text, 0.4);
      transform: none;
      box-shadow: none;

      &:after {
        opacity: 0;
      }
    }
  }
}

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for(small) using ($bp) {
  .c-button {
    $c: &;

    min-width: auto;

    &-group {
      display: flex;
      flex-flow: row wrap;
      gap: grid-baseline(3);

      #{ $c } {
        flex: 1 1 100%;
      }
    }

    &_content {
      span {
        + #{ $c }_icon {
          padding-left: grid-gutter(2);
        }
      }
    }
  }
}
