$env: production;
@use 'src/styles/base/mixins.scss';

.useful-links{
    &-cards-container{
        &:not(:last-of-type){
            margin-bottom: 32px;
        }
        &-title{
            @include mixins.neonColoredText;
            &-icon{
              svg{
                  fill: #c0c0c0;
              }
            }
        }
    }
}