$env: production;
.ribbon-container {
    position: absolute;
    right: -5%;
    top: -12.5%;
    z-index: 10;
}
.ribbon-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -55%);
    color: #0c645f;
    text-shadow: -1px -1px 0 #76ffa2, 1px -1px 0 #76ffa2, -1px 1px 0 #76ffa2, 1px 1px 0 #76ffa2;
    font-weight: 700;
    text-align: center;
    &_count {
        font-size: 36px;
    }
    &_label {
        font-size: 14px;
    }
}