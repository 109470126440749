$env: production;
.tool-tip {
    position: absolute;
    box-sizing: border-box;
    padding: 25px 20px;
    background: rgba(0, 0, 0, 0.7);
    border: 1px solid #ffffff;
    backdrop-filter: blur(40px);
    /* Note: backdrop-filter has minimal browser support */
    border-radius: 13px;
    width: 360px;
    height: 150px;
    right: 12%;
    top: 2%;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    animation:fade 4.5s ease-in-out forwards;

    &-push-down {
        top: 16%;

        @media (max-width:900px) {
            top: 14%;
        }
    }

    &-text {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        /* Gradient */
        background: linear-gradient(266.74deg, #B3FE00 45.07%, #00F644 97.3%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;

        @media (max-width:500px) {
            font-size: 24px;
        }

    }

    &-arrow {
        position: absolute;
        right: -22.5%;
        top: -25%;
        @media (max-width:500px) {
            right: -30%;
        }
        @media (max-width:350px) {
            right: -36.5%;
        }
        @media (max-width:300px) {
            right: -45%;
        }
    }

    @media (max-width:960px) {
        right: 13%;
    }

    @media (max-width:800px) {
        right: 15%;
    }

    @media (max-width:700px) {
        right: 17%;
    }

    @media (max-width:600px) {
        right: 19%;
    }

    @media (max-width:500px) {
        right: 21%;
        width: 260px;
        height: auto;
    }
    @media (max-width:400px) {
        right: 25%;
    }
    @media (max-width:350px) {
        width: 220px;
        right: 27.5%;
    }
    @media (max-width:300px) {
        width: 180px;
        right: 31%;
    }
}

@keyframes fade{
    0% {
        opacity:1;
    }

    95%{
        opacity:.5
    }
    98%{
        opacity:0.25
    }
    100%{
        opacity:0;
    }
}