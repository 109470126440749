@use 'styles/modules' as *;


//
//	RAYC / Styles / Themes
//


:root {
	@include color-theme-set( root );
}
.-t-brand {
	@include color-theme-set( brand );
}
// .-t-grey-light {
// 	@include color-theme-set( grey-light );
// }
// .-t-dark {
// 	@include color-theme-set( dark );
// }
// .-t-light {
// 	@include color-theme-set( light );
// }
// .-t-burnt-orange {
// 	@include color-theme-set( burnt-orange );
// }
// .-t-purple {
// 	@include color-theme-set( purple );
// }
// .-t-light-purple {
// 	@include color-theme-set( light-purple );
// }

.-color-primary { color: color-get( --primary ) !important; }
.-color-text { color: color-get( --text ) !important; }
.-color-text-mid { color: color-get( --text-mid ) !important; }
// .-color-text-light { color: color-get( --text-light ) !important; }
.-color-base { color: color-get( --base ) !important; }
.-color-base-110 { color: color-get( base-110 ) !important; }
// .-color-lime { color: color-get( ts-lime ) !important; }
// .-color-error { color: color-get( --state-error ) !important; }
// .-color-ts-blue-80 { color: color-get( ts-blue-80 ) !important; }
