$env: production;
@use 'src/styles/base/mixins.scss';

.your-collection {
    text-transform: uppercase;

  &-section {
    &-title {
      @include mixins.neonColoredText;
      @include mixins.centerRowVertically;
      font-weight: 700;
      font-size: 18px;
      line-height: 86.18%;
      &-icon{
        svg{
            fill: #c0c0c0;
            margin-right:8px;
        }
      }
    }
    &-cards-container {
      margin:32px 0;
      width:100%;
      height: auto;
      display: grid;
      grid-template-columns:repeat(4,1fr);
      gap:30px;
      // grid-row-gap:16px;
      // grid-column-gap: 30px;

      @media(min-width:2000px){
          grid-template-columns: repeat(5, 1fr);
      }
      @media(max-width:1200px){
          grid-template-columns: repeat(3, 1fr);
      }
      @media(max-width:1000px){
          grid-template-columns: repeat(2, 1fr);
      }
      @media(max-width:900px){
          grid-template-columns: repeat(3, 1fr);
      }
      @media(max-width:800px){
          grid-template-columns: repeat(2, 1fr);
      }
      @media(max-width:500px){
          grid-template-columns: repeat(1, 1fr);
      }
    }
  }
}
