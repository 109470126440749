$env: production;
$cards-side-margin: 7px;
$cards-vertical-margn: 25px;

@mixin centerColumnVertically {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@mixin centerRowVertically {
    display: flex;
    align-items: center;
}

.prev-carret {
    position: absolute;
    cursor: pointer;
    height: 64px;
    width: 64px;
    left: -10%;
    top: 50%;
    transform: rotate(180deg);

    @media (max-width: 1300px) {
        left: 15%;
        top: 25%;
    }

    @media (max-width: 900px) {
        left: 0%;
    }

    @media (max-width: 600px) {
       display: none;
    }
}

.next-carret {
    position: absolute;
    cursor: pointer;
    height: 64px;
    width: 64px;
    right: 0;
    top: 50%;

    @media (max-width: 1300px) {
        right: 15%;
        top: 25%;
    }

    @media (max-width: 900px) {
        right: 0%;
    }

    @media (max-width: 600px) {
       display: none;
    }
  
}


.apepe-odyssey-modal {
    top: 150px;
}

.apepe-odyssey {

    
    &_img-container {
        position: relative;
        border: 1px solid red;
    }


    &_logo {
        @media (max-width: 1000px) {
            padding: 0 30px;
        }
    }

    &_back-btn {
        // postioning
        top:-10%;
    }

    &_header {
        // font-size: 160px;
        text-align: center;
        padding: 0 10px;
        color: #033a40;
        letter-spacing: 0.02em;
        text-shadow: -1px 1px 0 #db76fe, 1px -1px 0 #db76fe, -1px 1px 0 #db76fe,
            1px 1px 0 #db76fe;
        margin-bottom: 20px;


        // text-shadow: -3px 4px 0 #db76fe, 2px -3px 0 #db76fe, 4px 2px 0 #db76fe,
        //   -2px -4px 0 #db76fe;
        @media (max-width: 1000px) {
            margin-bottom: 10px;
        }
    }

    &_sub-header {
        color: #8586ff;
        font-size: 28px;
        line-height: 140%;
        text-align: center;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: 50px;
    }

    &_paragraph {
        width: 467px;
        font-weight: 400;
        font-size: 14px;
        text-align: center;
        color: #d9d9d9;
    }

    //   main start
    &_main {
        width: 100%;
        max-width:1200px;
        min-height: 550px;
        max-height: auto;
        margin: 30px 0;
        margin-top: 40px;
        margin-bottom: 100px;
        padding-top:5%; // to give space for back btn to on top of content
        display: flex;
        justify-content: space-between;

        &_left {
            @include centerColumnVertically;
            justify-content:flex-start;
            height: auto;
            position:relative;
            &_img {
                position: relative;
                margin-bottom: 18px;

                &_ribbon {
                    top: -10% !important;

                    @media (max-width: 1300px) {
                        right: 5% !important;
                    }

                    @media (max-width:1000px) {
                        right: 5% !important;
                        top: -3% !important;
                    }

                    @media (max-width:500px) {
                        right: 2% !important;
                        top: -6% !important;
                    }
                }

                @media (max-width: 1000px) {
                    padding: 0 30px;
                    margin-top: 16px;
                }
            }

            &_cta {
                @include centerRowVertically;
                color: #b3fe00;
                font-size: 14px;
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 20px;
                cursor: pointer;

                &_icon {
                    margin-right: 9px;
                    width: 25px;
                    height: 25px;
                }
            }

            @media (max-width: 760px) {
                margin-bottom: 40px;
            }
        }

        &_right {
            width: 642px;
            height: auto;
        

            &_info {
                &_item {
                    &:not(:first-of-type) {  // needs scss
                        margin-top: 24px;
                    }
                }
            }

            &_mint {
                width: 100%;
                height: auto;
                margin-top: 30px;
                @include centerRowVertically;

                &_button {
                    @media (min-width: 800px) {
                        margin-left: 50px;
                    }                        
                }

                &_count {
                    font-weight: 700;
                    font-size: 36px;
                    color: #0c645f;
                    text-transform: uppercase;
                    margin: 0 15px;
                    text-shadow: 0px -1px 0 #76ffa2, -1px -1px 0 #76ffa2,
                        -1px 1px 0 #76ffa2, 1px 1px 0 #76ffa2;
                    margin-right: 34px;

                    &_btn {
                        overflow-anchor: none;
                        &_disabled {
                            opacity: 0.4;
                        }
                    }
                }

                &_count-manager {
                    cursor: pointer;
                    @include centerRowVertically;
                }

                &_btn {
                    @media (max-width: 1000px) {
                        margin-left: 0;
                        width: 75%;
                        margin-top: 10px;
                    }

                    @media (max-width: 500px) {
                        margin-top: 20px;
                        width: 100%;
                    }
                }

                @media (max-width: 1000px) {
                    flex-direction: column;
                    align-items: flex-start;

                    &> :nth-child(1) {
                        margin: 20px 0;
                    }
                }
            }
        }
    }

    //   main end
    &_btn {
        margin-top: 50px;
    }
}