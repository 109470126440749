$env: production;
.meme-card {
  width: 160px;
  height: 153px;
  border-radius: 16px;
  border: 1px solid #212121;
  margin-bottom: 16px;
  cursor: pointer;
  transition: 0.2s ease-in forwards;

  &-image {
    width: 100%;
    height: 100px;
    background-size: contain;
    background-repeat:no-repeat;
    background-position: center;
  }

  &-text {
    padding: 16px;
    color:white;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 20.5px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #141414;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  
  &:hover,
  &-selected {
    border-color: #b3fe00;
  }

  @media (max-width: 550px) {
    width: 200px;
  }
  @media (max-width:450px) {
    width: 160px;
  }
  @media (max-width:375px) {
    width: 145px;
  }
}
