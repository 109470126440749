$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Sections / Image
//


.s-image {
	$s: &;

	aspect-ratio: 16/9; //0.73
	padding: 0;
	position: relative;

	//	Elements   ------------------

	&_guide {
		width: 100px; height: 10px;
		position: absolute; top: 0; right: 0;
		background-color: limegreen;
	}

}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {

	.s-image {
		$c: &;

		aspect-ratio: 3/4; //0.73

	}
}