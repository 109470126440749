$env: production;
@use 'src/styles/base/mixins.scss';

.your-collection-card {
  position: relative;
  width: 225px;
  min-height: 322px;
  border-radius: 10px;
  cursor:pointer;

  &-ribbon{
    display:flex;
    align-items:center;
    position: absolute;
    z-index: 2;
    top:-7.5%;
    right:-7.5%;
    width:70px;
    height:80px;
  }
  &-image-container {
    width: auto;
    height: auto;
    border-radius: 14px 14px 0px 0px;
    background: #313131;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    
    img{
      visibility: hidden;
    }
  }
  &-img{
    border-radius: 14px 14px 0px 0px;
  }
  &-text-container {
    box-sizing: border-box;
    width: 100%;
    height: 97px;
    background: #141414;
    border-radius: 0px 0px 12px 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px 30px;
  }
  &-title,
  &-sub-title {
    font-size: 12px;
    opacity: .5;
  }
  &-title{
    font-weight: 700;
  }
  &-sub-title{
    font-weight:400;
  }

  &-cta {
    margin-top: 8px;
    font-size: 14px;
    @include mixins.centerRowVertically;
    @include mixins.neonColoredText;

    &-icon {
      svg {
        width: 21px;
        height: 21px;
        fill: #b3fe00;
        margin-top:-2px;
      }
    }
  }
  
  @media(max-width:500px){
    margin: 16px auto;
  }
}
