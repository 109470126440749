$env: production;
@use 'styles/modules' as *;

//
//	RAYC / UI / App / Footer
//

.a-footer {
	$c: &;


	//	Elements
	&_push-up {
		margin-top: -7rem;
		@media (max-width: 900px) {
			margin-top: -10rem;
		}
	  }


	&_slime {
		margin-top: -1px;
	}
}

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {
	.a-footer {
		$c: &;
	}
}