$env: production;
.cta-bar {
  box-sizing: border-box;
  height: 126px;
  background: #141414;
  border: 1px solid #212121;
  border-radius: 14px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width:900px) {
    flex-direction:column;
    height:auto;
  }
}
