$env: production;
.modal-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 1000;
}
.modal-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
}

.modal-content {
    box-sizing: border-box;
    min-height: 391px;
    width: 676px;
    background: #000000;
    border: 1px solid #ffffff;
    border-radius: 8px;
    position: fixed;
    top: 75px;
    z-index: 2;
    padding: 45px 40px;
    display: flex;
    flex-direction: column;
    &-push-down{
        top:5%;
    }
    &-control-bar {
        position: relative;
        margin-bottom: 20px;
        min-height: 20px;
    }
    &-icon {
        cursor: pointer;
        float:right;
    }

    &-back-btn {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 160%;
        color: #b3fe00;
        text-transform: uppercase;
    }
    &-main {
   
        min-height: 280px;
        max-height: auto;

        &-flex{
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            @media (max-width: 700px) {
                flex-direction: column;
                align-items: center;
            }
        }
        &-side {
            width: 50%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 700px) {
                width: 100%;
                align-items: center;
            }
        }
        &-header {
            margin: 0;
            text-transform: uppercase;
        }
        &-img {
            @media (max-width: 700px) {
                display: none;
            }
        }
        &-paragraph {
            font-size: 14px;
            font-weight: 500;
            color: #c0c0c0;
            a {
                color: #ffffff;
            }
        }
        &-learn-more {
            color: #b3fe00;
            font-weight: 600;
            font-size: 14px;
            text-transform: uppercase;
            margin: 0;
            padding: 0;
        }
       
    }
    @media (max-width: 700px) {
        width: 334px;
        top:1.25%;
    }
}
