$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Views / Loading
//


.v-loading {
	$v: &;

	position: fixed; inset: 0;
	background-color: color-get( --bg );
	height: 100vh;
	z-index: 110;

	section {
		display: flex; align-items: center;
		height: 100vh;
	}

	p {
		opacity: 0.5;
	}

	&_img {
		max-width: 60vw;
		margin: 0 auto;
	}

	// States

	&.-is-loaded {
		pointer-events: none;
	}
}