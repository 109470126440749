$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Icons
//


.c-icon {
	--baseline: #{ type-size-responsive( 10, 12 ) };

	width: clamp( grid-baseline(3), grid-gutter(1), grid-baseline(4) );
	height: auto;
	max-width: 100%; max-height: 100%;
	position: relative;
	display: inline-flex; align-items: center; justify-content: center;
	flex-shrink: 0;
	color: currentColor;
	vertical-align: top;

	//	Elements

	svg {
		width: 100%; height: 100%;
		stroke-width: 2px;
	}

	span {
		z-index: 1;
	}
}
