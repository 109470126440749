$env: production;
@use 'src/styles/base/mixins.scss';

.connect-bar {

  &-text {
    max-width: 50%;
    line-height: 87%;

    span {
      @include mixins.neonColoredText;
    }

    @media (max-width: 900px) {
      max-width: 75%;
      text-align: center;
    }
    @media (max-width: 500px) {
      font-size: 20px;
      max-width: 100%;
    }
  }

  &-button {
    max-width: 50%;
    font-size: 32px;

    @media (max-width: 1075px) {
      font-size: 25px;
    }
    @media (max-width: 900px) {
      font-size: 32px;
      margin-top: 24px;
      max-width: 75%;
    }
    @media (max-width: 500px) {
      font-size: 20px;
      padding: 12px 18px;
      max-width:100%;
    }
  }

  @media (max-width: 1300px) {
    padding: 30px;
  }
  
}
