$env: production;
@charset 'utf-8';


//
//	RAYC / Styles / Main
//
//	Environment
$env: 'none' !default;
@debug #{ '→ Build Environment:' $env };

//	Dev overlays
$dev_show-helper: true !default;
$dev_show-grid: false !default;
$dev_show-baseline: true !default;

$easing: cubic-bezier(0.4, 0, 0, 1);

@import 'base';
@import 'layout';
@import 'fonts';


@keyframes pulse {
	0% {
		transform: scale(0.8);
	}

	70% {
		transform: scale(1);
	}

	100% {
		transform: scale(0.8);
	}
}
