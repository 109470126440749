$env: production;
@use "styles/modules" as *;

//
//	RAYC / UI / App / Header
//

.a-header {
  $a: &;

  &_push-down {
    margin-bottom: -10.75rem; // this one has to be negative margin, transform translate wont fit the scenario
    backdrop-filter: blur(20px);
  }

  &_secondary {
    background-color: rgba(0, 0, 0, 0.5);
  }

  &_marquee {
    background: linear-gradient(266.74deg, color-get(rayc-lime) 45.07%, color-get(rayc-green) 97.3%);

    &-img {
      width: grid-baseline(2.5);
      margin-right: grid-baseline(2);
    }

    span {
      display: contents;
    }
  }

  &_text-link {
    background: linear-gradient(266.74deg, #b3fe00 45.07%, #00f644 97.3%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

  &_container {
    padding: 0 calc(var(--grid-gutter-width) / 2);
  }

  //	Elements

  &_bar {
    @include color-set(--link-hover, --link);
    width: var(--grid-width);
    max-width: 94%;
    display: flex;
    align-items: center;
    flex: 1 1 auto;
    padding: grid-baseline(4) 0;
  }
  // decreasing padding for headers with push down effect
  &_push-down &_bar {
    // disabling push down padding removal for mobile navbars
    @media (min-width: 970px) {
      padding: grid-baseline(3) 0;
    }
  }

  &_logo-container {
    margin-right: grid-gutter(2);
  }

  &_logo {
    @include color-set(--text, --base);
    width: grid-gutter(3);
    max-width: grid-baseline(10);
    min-width: grid-baseline(5);
  }

  &_nav {
    gap: grid-gutter(1.5);
    z-index: 10;
  }

  &_serum-zombie {
    gap: grid-gutter(0.75);

    a {
      &:hover {
        opacity: 0.5;
      }
    }
  }

  &_socials {
    gap: grid-gutter(0.75);

    p {
      font-size: type-size-responsive(20, 30);
      margin: 0 grid-gutter(0.75);
    }
  }

  //	States
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for(small) using ($bp) {
  .a-header {
    $a: &;

    position: relative;
    z-index: 100;
    height: grid-baseline(15);

    &_bar {
      padding: 0;
    }

    &_logo {
      max-width: grid-baseline(5);
    }

    &_socials {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }

    &_menu {
      &-button {
        margin-right: grid-baseline(-2);
      }
    }
  }
}
