$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Image
//

.grayscale {
	filter: grayscale(100%);
}

.c-image {
	$c: &;

	width: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;

	&:global(.-hide-s) {
		display: block;
	}
}

.c-image-canvas {
	aspect-ratio: 16/9;
}

.c-image-parallax {
	width: 100%; height: 100%;
	position: relative;
	overflow: hidden;
	transform: translate3d(0,0,0);

	> .c-image {
		position: absolute; top: 0; right: 0; bottom: 0; left: 0;
		display: block;
		transform: translate3d(0,0,0);
		will-change: transform;

		> span {
			height: 100% !important;
		}
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {

	.c-image-canvas {
		$c: &;

		aspect-ratio: 3/4; //0.73

	}
}
