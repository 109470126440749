$env: production;
.delegate-modal {
    &-title {
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 103.68%;
        @media (max-width: 700px) {
            font-size: 36px;
        }
    }

    &-paragraph {
        margin: 0;
    }
    &-info-section {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 40px;

        @media (max-width: 700px) {
            flex-direction: column;
            &>*{
                &:not(:first-of-type){
                    margin-top:20px
                }
            }
        }
    }
    &-cta {
        margin: 0;
        padding: 0;
        margin-top: 40px;
        font-weight: 400;
        font-size: 12px;
        color: #b3fe00;
        border: 1px solid #b3fe00;
        border-radius: 31px;
        padding: 4px 10px;
        cursor: pointer;
        &-desktop {
            @media (max-width: 700px) {
                display: none;
            }
        }
        &-mobile{
            display: none;
            @media (max-width: 700px) {
                display: block;
            }
        }
    }
}
