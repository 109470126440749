@use 'sass:math';
@use 'styles/modules' as *;


//
//	RAYC / Styles / Layout
//


.a-layout {
	$a: &;

	width: 100vw; min-height: 100vh;
	box-sizing: border-box;
	position: relative; z-index: 10;
	overflow: hidden;

	// > header {
	// 	position: fixed; top: 0; z-index: 100;
	// }

	.scroll-container {
		width: 100%; height: 100vh;
		position: fixed; inset: 0; bottom: auto;
		overflow: hidden !important;
		visibility: hidden;

		&.-is-loaded {
			visibility: visible;
		}
	}

	main {
		width: 100%; //height: 100%;
		// background-color: color-get( --bg );
		transform: translate3d(0,0,0);
		will-change: transform;
		backface-visibility: hidden;

		&.-is-smooth-scroll {
			position: fixed; inset: 0; z-index: 10;

			section.section {
				// margin-bottom: -2px;
			}
		}

		section {
			box-sizing: border-box;
			justify-content: center;
			backface-visibility: hidden;
			will-change: transform;
			margin-bottom: -1px;

			.-span-2 {
				grid-column: span 2;
			}

			.-span-3 {
				grid-column: span 4;
			}

			&.-hm100 {
				min-height: 100vh;
			}
			.-span-full {
				width: 100%;
				grid-column: 1 / -1;
			}
		}
	}

	&_cursor {
		display: none;
		pointer-events: none;

		@media (hover: hover) {
			position: fixed;
			top: grid-baseline(10); left: grid-baseline(10);
			z-index: 110;
			display: flex;
			width: clamp( grid-baseline(12), grid-gutter(4), grid-baseline(15));
			height: clamp( grid-baseline(12), grid-gutter(4), grid-baseline(15));

			&:not(.-is-disabled) {
				#{ $a }_cursor-inner {
					visibility: visible;
					overflow: hidden;
					opacity: 1;
					transform: scale(0.85);
				}
			}

			#{ $a }_cursor-inner {
				position: absolute; inset: 0;
				display: flex;
				align-items: center; justify-content: center;
				background-color: rgba(102, 134, 162, 0.1);
				backdrop-filter: blur(12px);
				// border: 1px solid color-get( white, 0.15 );
				border-radius: 50%;
				opacity: 0;
				visibility: hidden;
				will-change: transform, opacity;

				transform: scale(0.1);
				transform-origin: 0 0;
				transition: opacity 0.3s, transform 0.3s, visibility 0.3s;
			}
		}
	}

	&_nav {


		&-buttons {
			position: fixed; top: 50vh; right: 3vw; z-index: 30;
			display: flex; flex-direction: column; align-items: flex-end;
			mix-blend-mode: difference;
			transform: translate3d(0, -37px, 0);
		}
			&-button {
				width: 1vw; height: 15px;
				position: relative;
				transition: width, opacity;
				transition-duration: 0.6s;
				transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
				opacity: 0.25;

				&::after {
					content: '';
					width: 100%; height: 3px;
					position: absolute; top: 6px; right: 0;
					background-color: color-get( white );
					transition: background-color;
					transition-duration: 0.6s;
					transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
				}

				&:hover {
					opacity: 1 !important;

					&::after {
						background-color: color-get( ts-lime );
					}
				}
			}

		&-tag {
			@include type-size( f9, $lines: 2, $margin: 0 );
			padding-left: grid-baseline(1.25) !important;
			padding-right: grid-baseline(1.25) !important;
			letter-spacing: 1px;
			position: fixed; top: 50vh; right: 6vw; z-index: 30;
			border-radius: 4px;
			transform: translate3d(0, -42px, 0);
			transition: transform;
			transition-duration: 0.6s;
			transition-timing-function: cubic-bezier(0.25, 1, 0.5, 1);
		}
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {

	.a-layout {

		.scroll-container {
			// position: relative;
			// overflow-y: auto !important;
		}

		// main {
		// 	position: relative;
		// 	transform: none;
		// 	will-change: auto;

		// 	&.-is-smooth-scroll {
		// 		position: relative;
		// 	}

		// 	section {
		// 		will-change: auto;

		// 		&.-hm100 {
		// 			min-height: auto;
		// 		}
		// 	}
		// }

		&_nav {
			display: none;
		}
	}

}
