$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Views / Home
//


.v-home {
	$v: &;


	&_odyssey{
		width: 100%;
		height: auto;
		padding: 50px 0;
		margin-bottom: 70px !important;
		text-align:center;

		background: url(/images/apepeOdysseybg.webp);
		background-position: center top;
		background-size: cover;

		&_header{
			text-transform: none;
		}
		&_paragraph{
			width: 467px;

			@media(max-width:500px){
			width: 80%;
			}
		}
		
		&_text-content{
			width:100%;
			display:flex;
			flex-direction:column;
			align-items:center;
			margin-bottom:30px
		}
		&_button{
			width:400px;
			height:100px;
			font-size: 45px;

			@media(max-width:500px){
				font-size: 9vw;
				width:75%;

				}
		}
	
	}
	&_serum {
		position: relative;
		display: flex;
		width: 100%;
		margin-bottom: 70px !important;

		&-left {
			position: relative;
			display: flex;
			width: 40%;
			flex-direction: column;
			align-items: center;
			gap: grid-gutter(1);

			.star {
				position: absolute;
				width: 110px;
				height: 110px;
				top: -48px;
				left: 90px;
			}

			.title {
				width: 630px;
				text-align: center;
				padding-top: 20px;
				transform: rotate(-3.72deg);

				font-family: 'burbank';
				font-weight: 700;
				font-size: 102px;
				line-height: 76px;

				background-image: linear-gradient(271.23deg, #60FFCF 19.94%, #FFFFFF 63.65%, #5019D7 103.04%);
				-webkit-text-stroke: .05em #091F36;

				
				background-clip: text;
				-webkit-background-clip: text;
  			-webkit-text-fill-color: transparent;
			}

			.subtitle {
				font-family: 'burbank';
				font-weight: 700;
				font-size: 36px;
				line-height: 40px;

				background: linear-gradient(271.23deg, #60FFCF 19.94%, #FFFFFF 63.65%);
				text-align: center;

				background-clip: text;
				-webkit-background-clip: text;
  			-webkit-text-fill-color: transparent;
			}

			.text {
				font-weight: 400;
				font-size: 16px;
				width: 310px;
				line-height: 140%;
				text-align: center;
				opacity: 0.7;
			}
		}

		&-right {
			display: flex;
			width: 40%;
			flex-direction: column;
			align-items: center;

			.card {
				filter: drop-shadow(0px 7.72919px 9.44678px rgba(0, 0, 0, 0.25));
				transform: rotate(7.7deg);
			}
		}
	}

	&_zombies {
		position: relative;
		display: flex;
    align-items: center;
		width: 100%;
    height: 672px;
		margin-bottom: 70px !important;
		background: url(/images/zombie_bg.webp);
    background-position: center top;

		&-left {
			display: flex;
			width: 50%;
			flex-direction: column;
			align-items: center;
		}

		&-right {
			position: relative;
			display: flex;
			width: 50%;
			flex-direction: column;
			align-items: center;
			gap: grid-gutter(1);

			.zombiesImage {
				margin-bottom: -15px;
				filter: drop-shadow(10px 11px 7px rgba(0, 0, 0, 0.15));
				border-radius: 10px;
			}

			.title1 {
				font-family: 'burbank';
				font-weight: 700;
				font-size: 36px;
				line-height: 40px;

				.part1 {
					color: color-get( white )
				}
				.part2 {
					background: linear-gradient(271.23deg, #B3FE00 70.94%, #00F644 94.65%);
					text-align: center;

					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
			}

			.title2 {
				text-align: center;
				background: linear-gradient(180deg, #071C32 0%, #0C1568 100%);

				font-family: 'burbank';
				font-weight: 700;
				font-size: 62px;
				line-height: 60px;

				-webkit-text-stroke: .015em #93AECF;


            text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.1), 1px -1px 0 rgba(0, 0, 0, 0.1), -1px 1px 0 rgba(0, 0, 0, 0.1), 1px 1px 0 rgba(0, 0, 0, 0.1);

				// text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

				background-clip: text;
				-webkit-background-clip: text;
  			-webkit-text-fill-color: transparent;
			}

			.text {
				font-weight: 400;
				font-size: 16px;
				width: 465px;
				line-height: 140%;
				text-align: center;
				opacity: 0.7;
			}
		}
	}

	&_lineage {
		position: relative;
		z-index: 1;

		&-container {
			position: relative;
		}

		&-card {
			&:first-child {
				margin-right: grid-gutter(2);
				z-index: 1;
			}

			&:last-child {
				margin-left: grid-gutter(2);
			}
		}

		&-lightning {
			position: absolute;
			top: grid-gutter(1.25); bottom: -10%; left: 50%;
			transform: translate(-50%, 0);
			width: 100%; max-width: 55%;
			z-index: -1;

			> div {
				height: 100%;
			}

			&_top {
				position: absolute;
				top: -8%; right: -0; bottom: 0;
				width: 100%;

				> div {
					height: 115%; width: auto;
				}
			}

			&_btm {
				position: absolute;
				right: 0; bottom: 0; left: 10%;
				transform: translateY(80%);
			}
		}

		&-logo {
			background-color: color-get(--bg);

			&_container {
				position: relative;
			}
		}

			&-explosion {
				position: absolute;
				transform-origin: 56% 28%;
				transform: rotate(-25deg) scale(2.25);
			}
	}

	&_gallery {
		position: relative;

		&-bg {
			position: absolute;
			top: -1px; right: 0; left: 0;
			z-index: 1;
			will-change: transform;
			transform: translate3d(0,0,0);

			&::before {
				content: '';
				height: 10vw;
				position: absolute; right: 0; bottom: 100%; left: 0;
				display: block;
				background-color: color-get( black );
			}
		}

		&-container {
			display: grid;
			grid-template-columns: repeat( 4, minmax( auto, 1fr ) );

			> div {
				aspect-ratio: 1;
			}
		}
	}

	&_story {

		&-images {
			margin-top: 10vw;
			margin-right: grid-column(1);
			margin-bottom: grid-baseline(11);
		}

		&-frame {
			position: relative;
			margin-bottom: grid-baseline(6);

			&_img {
				z-index: 1;
			}

			> div:not( #{ $v }_story-frame_img ) {
				position: absolute;
				inset: 0;
				max-width: 55%;
				margin: 0 auto;
			}
		}

		&-subtitle {
			letter-spacing: 1px;
		}
	}

	&_rarelabs-marquee {
		&_img {
			width: grid-baseline(7);
			margin-right: grid-baseline(5);

			&:not(:first-child) {
				margin-left: grid-baseline(5);
			}
		}
	}

	&_map {
		position: relative;
		overflow: hidden;

		&::after {
			content: '';
			position: absolute;
			right: 0; bottom: 0; left: 0;
			height: 50vh;
			background: linear-gradient(0deg, color-get( --bg ) 0%, color-get( --bg, 0 ) 100%)
		}

		&-bg {
			position: absolute;
			inset: 0;
			margin-top: -20%;
			transform: scale(1.25);
			opacity: 0.1;

			> div {
				width: auto;
			}
		}

		&-content {
			z-index: 1;
		}

		&-timeline {
			position: relative;
			display: flex;
			flex-direction: column;
			gap: grid-baseline(4);
			margin-top: grid-baseline(7.5);

			:global(.grid) {
				grid-column-gap: grid-column(1.5);
			}

			&::after {
				content: '';
				position: absolute;
				width: 1px;
				background-color: color-get( --base );
				top: 0; bottom: 0; left: 50%;
			}

			&::before {
				content: '';
				position: absolute;
				z-index: 1;
				bottom: 0; left: 49%;
				height: 10%; width: 2%;
				background: linear-gradient(0deg, color-get( --bg ) 0%, color-get( --bg, 0 ) 100%);
			}

			> div:nth-child(odd) {

				#{ $v }_map-timeline_content {
					align-items: flex-end;
					text-align: right;
				}
			}

			&_img {
				max-width: grid-baseline(38);

				&-artificat {
					max-width: grid-baseline(34);
				}
			}
		}
	}

	&_bar {
		height: 50vw;
		overflow: hidden;
	}

	&_team {
		position: relative;
		overflow: hidden;
		padding-bottom: 0;

		&-bg {
			position: absolute;
			inset: 0;
			margin-top: -5%;
			opacity: 0.7;
		}

		&-member {
			justify-content: flex-start;

			&_link {
				display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
			}
		}

		&-img {
			max-width: grid-gutter(7.5);
			border-radius: grid-baseline(3);
			overflow: hidden;
			box-shadow: 0px 0px 48px -2px color-get( --primary, 0.88 ), 0px 0px 0px 4px color-get( --primary );
		}
	}
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {
	.v-home {
		$v: &;

		&_lineage {
			&-card {
				&:first-child {
					margin-right: 0;
				}

				&:last-child {
					margin-left: 0;
				}
			}
		}

		&_serum {
			flex-direction: column-reverse;
			align-items: center;
			gap: 10px;

			&-left {
				width: 60%;
				gap: 30px;

				.star {
					top: -53px;
					left: 0;
				}
				.title {
					font-size: 50px;
					width: 300px;
    			line-height: 40px;
				}
				.subtitle {
					width: 280px;
				}
			}

			&-right {
				width: 60%;
			}
		}

		&_zombies {
			flex-direction: column;
			gap: 10px;
			margin-bottom: 50px !important;
			padding-bottom: 50px;
			background-repeat: repeat-y;
			height: 100%;

			&-left {
				width: 90%;
			}

			&-right {
				width: 90%;
				gap: 10px;
				margin-top: -40px;

				.title1 {
					text-align: center;
					font-size: 36px;
					width: 300px;
    			line-height: 40px;
				}
				.title2 {
					font-size: 40px;
				}
				.text {
					width: 300px;
					margin-bottom: 20px;
				}
			}
		}

		&_gallery {
			&-bg {
				&::before {
					height: 20vw;
				}
			}
			&-container {
				grid-template-columns: repeat(2, minmax(auto, 1fr));
				transform: translateY( grid-baseline(-7));
			}
		}

		&_story {
			&-images {
				margin-top: grid-baseline(-30);
				margin-right: 0;
				padding: 0 grid-gutter(3.5);
			}

			&-frame {
				margin-bottom: grid-baseline(3);
			}
		}

		&_map {

			&:global(.section) {
				padding-bottom: 0;
			}

			&-timeline {
				gap: 0;
				margin: 0 grid-gutter(3.5);

				&_content {
					order: 2;
				}

				&_img {
					width: clamp(grid-baseline(30), grid-gutter(35), grid-baseline(50) );
					margin-bottom: grid-baseline(3);
				}

				> div:nth-child(odd) {

					#{ $v }_map-timeline_content {
						align-items: flex-start;
						text-align: left;
					}
				}

				> div {
					position: relative;
					padding-bottom: grid-baseline(6);

					&::after {
						content: '';
						position: absolute;
						left: grid-gutter(-3.5); top: 0; bottom: 0;
						width: 1px; height: 100%;
						background-color: color-get( --base );
					}
				}

				&::after, &::before {
					content: none;
				}
			}
		}

		&_join {

			&-container {
				margin: 0 grid-gutter(3.5);

				> div {
					position: relative;

					&:before, &::after {
						content: '';
						position: absolute;
						left: grid-gutter(-3.5); bottom: 0;
					}

					&::before {
						z-index: 1;
						width: 2%; height: 250%;
						// background: linear-gradient(0deg, color-get( --bg ) 0%, color-get( --bg, 0 ) 100%); // commented out as it was overlapping on top of the Toadmap
					}

					&::after {
						top: 0;
						width: 1px; height: 100%;
						// background-color: color-get( --base ); // commented out as it was overlapping on top of the Toadmap
					}
				}
			}
		}

		&_team {
			padding-bottom: grid-baseline(10);

			&-img {
				max-width: grid-gutter(17);
				min-width: grid-baseline(20);
				border-radius: grid-baseline(2.5);
			}
		}
	}
}
