@use 'sass:math';
@use 'styles/core';
@use 'styles/modules' as *;


//
//	RAYC / Base / Layout
//


:root {
	--baseline: #{ type-size-responsive( 6, 10 ) };
}

//	Placeholders
%section {
	display: flex;
	flex-direction: column; justify-content: stretch;
	// background-color: color-get( --bg );
}
	%grid {
		width: var(--grid-width);
		max-width: var(--grid-max-width);
		margin-left: auto; margin-right: auto; padding: 0 calc( var(--grid-gutter-width) / 2 ); box-sizing: border-box;
		display: grid;
		grid-template-columns: [start] repeat( var(--grid-columns), minmax( auto, 1fr ) ) [end];
		grid-template-rows: auto;
		grid-column-gap: var(--grid-gutter-width);

		> div {
			display: flex; flex-direction: column;

			&:not([class*="-ha-"]) {
				align-items: flex-start;
			}

			&:not([class*="-g-min-"]) {
				min-width: 0;
			}
		}

		&.-g-auto {
			grid-template-columns: [start] repeat( var(--grid-columns), minmax( min-content, auto ) ) [end];
		}

		&.-g-hs {
			flex: 0 1 auto;
		}
		&.-g-hg {
			flex: 1 1 auto;
		}

		&.-va-c {
			justify-items: center;
		}

		.-va-c {
			justify-content: center;
		}

		//	Helpers	 ------------

		&.-max-100 {
			max-width: 100%;
		}
	}


//	Layout

$grid-cols: core.get( $grid-config, 'default.columns' );

.section {
	padding: grid-baseline(22) 0;
	@extend %section;

	&.-ps { padding: grid-baseline(10) 0; }

	&.-pl { padding: grid-baseline(40) 0; }

	&.-ptl { padding-top: grid-baseline(40); }

	&.-pbl { padding-bottom: grid-baseline(40); }

	&.-pts { padding-top: grid-baseline(10); }

	&.-pbs { padding-bottom: grid-baseline(10); }
}
	.grid {
		@extend %grid;
		min-width: 0;

		.grid {
			padding-left: 0; padding-right: 0;

			&:not([class*="-g-max-"]) {
				max-width: 100%;
			}

			&[class*="-g-max-"] {
				margin-left: 0; margin-right: 0;
			}
		}

		@for $i from 1 through $grid-cols {
			&.-g-cols-#{$i} {
				--grid-columns: #{$i};
			}
		}
	}


@each $bp-key, $bp-map in $breakpoint-config {
	@include breakpoint-for( #{$bp-key} ) using ( $bp ) {
		$grid-cols: core.get( $grid-config, '#{$bp-key}.columns' );
		$bp-max: core.get( $bp, 'max' ); // percentage
		$bp-width: core.get( $bp, 'width' ); // px

		:root {
			--breakpoint: "#{$bp-key}";
			--breakpoint-width: #{$bp-width};
			--grid-width: #{grid-column( $grid-cols )};
			--grid-max-width: #{percentage( math.div( $bp-max, 100 ) )};
			--grid-columns: #{$grid-cols};
			--grid-column-width: #{grid-column(1)};
			--grid-gutter-width: #{grid-gutter(1)};
		}
			@for $i from 1 through $grid-cols {
				.-g-cols-start-#{$i} {
					grid-column-start: #{$i};
				}
				.-g-cols-fill-#{$i} {
					grid-column-start: span #{$i};
				}
				.-g-max-#{$i} {
					max-width: min( #{ grid-column($i) }, #{ $bp-max * 1vw } );
				}
				.-g-min-#{$i} {
					min-width: grid-column($i);
				}
			}

			@if $bp-key == small {
				.-s\:g-min-none { min-width: 0; }
				.-s\:g-max-none { max-width: var(--grid-max-width); }
			}
	}
}


@include breakpoint-for( small ) using ( $bp ) {
	$grid-cols: core.get( $grid-config, 'small.columns' );
	$bp-max: core.get( $bp, 'max' );

	.section {
		padding: grid-baseline(12) 0;

		&.-pts { padding-top: grid-baseline(3); }

		&.-pbs { padding-bottom: grid-baseline(3); }

		&.-pl { padding: grid-baseline(12) 0; }

		&.-ptl { padding-top: grid-baseline(12); }

		&.-pbl { padding-bottom: grid-baseline(12); }
	}

	%grid {
		.grid {
			&[class*="-g-max-"] {
				max-width: 100%;
			}
		}

		&.-g-cols-1,
		&.-g-cols-2,
		&.-g-cols-3,
		&.-g-cols-4 {
			--grid-columns: 1;
		}

		&.-s\:g-full {
			width: 100%;
			margin: 0;
			padding: 0;
			max-width: none;
		}

		@for $i from 1 through $grid-cols {
			.-g-cols-start-#{$i} {
				grid-column-start: auto;
			}
			.-s\:g-cols-start-#{$i} {
				grid-column-start: #{$i};
			}
			.-g-cols-fill-#{$i} {
				grid-column-start: span 1;
			}
			&.-s\:g-cols-#{$i} {
				--grid-columns: #{$i};
			}
		}

		&[class*="-g-max-"] {
			max-width: var(--grid-max-width);
		}
	}

	[class*="-g-max-"] {
		max-width: none;
	}

	@for $i from 1 through $grid-cols {
		.-s\:g-max-#{$i} {
			max-width: min( #{ grid-column($i) }, #{ $bp-max * 1vw } );
		}
	}
}

// Positions

.-p-relative {
	position: relative;
}

.-p-absolute {
	position: absolute;
}
