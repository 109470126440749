$env: production;
.dashboard-footer {
  margin-left: 11%;

  &-cards {

    &::-webkit-scrollbar {
      display: none;
    }

    &-item {
      min-width: 249px;
      height: 416px;

      &-avatar {
        background: #797979;
      }

      &-upper {
        height: 250px;
        background: #292929;
        border-radius: 20px 20px 0px 0px;

        &-info {
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        }
      }

      &-lower {
        height: calc(100% - 250px);
        border-radius: 0px 0px 12px 12px;
      }

   
      &-cta {
        color: #b3fe00;
        border: 1px solid #b3fe00;
        border-radius: 31px;
      }

      &:not(:first-of-type) {
        margin: 0 24px;
      }
      &:first-of-type {
        margin-right: 24px;
      }
      
    }
  }

  @media (max-width: 900px) {
    margin-top: 50px;
  }
}
