$env: production;
.info-card {
  position: relative;
  background: #141414;
  padding: 16px 24px;
  border: 1px solid #212121;
  border-radius: 14px;
  color: #c0c0c0;

  &:not(:first-of-type) {
    margin: 16px 0;
  }
  &:not(:last-of-type) {
    margin: 16px 0;
  }
  &:last-of-type {
    margin-top: 16px;
  }
  &:first-of-type {
    margin-bottom: 16px;
  }

  &-lower-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 900px) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &-title {
    margin-bottom: 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    &-circle {
      width: 4px;
      height: 4px;
      background: #b3fe00;
      border-radius: 50%;
      margin-right: 8px;
    }
    &-fade{
    opacity: 0.7;
    }
  }
  &-text{
    width: 85%;
  }
  &-paragraph,&-sub-paragraph{
    width: 100%;
    font-weight:400;

    @media (max-width: 900px) {
      width: 100%;
      margin-bottom: 16px;
    }
  }
  &-paragraph {
    color: #c0c0c0;

    &-bold{
      font-weight: 700;
      color:white;
      margin-bottom: 0;
    }
  }
  &-sub-paragraph{
    color:inherit;
    margin-top:0;
    font-size: 12px;
  }



  &-cta {
    color: #b3fe00;
    font-size: 12px;
    opacity: 0.7;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
    padding: 0;

    &-contained {
      border: 1px solid #b3fe00;
      border-radius: 31px;
      font-weight: 400;
      padding: 4px 10px;
    }
  }
}
