$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Sections / Rare Labs
//


.s-rarelabs {
	$s: &;

	position: relative;

	&::after,
	&:before {
		content: '';
		position: absolute;
		right: 0; left: 0;
		z-index: 1;
		height: 30%;
	}

	&::before {
		top: 0;
		background: linear-gradient(180deg, color-get( --bg ) 0%, color-get( --bg, 0 ) 100%)
	}

	&::after {
		bottom: 0;
		background: linear-gradient(0deg, color-get( --bg ) 0%, color-get( --bg, 0 ) 100%);
	}

	&-bg {
		position: absolute;
		inset: 0;
		opacity: 0.3;
		overflow: hidden;

		> div {
			height: 100%;
		}
	}

	&-content {
		position: relative;
		z-index: 2;
		background-color: transparent;
	}
		&-title {
			position: relative;
			display: flex;
			align-items: center; justify-content: center;
			width: 100%;

			&_language {
				font-size: type-size-responsive( 24, 28 );
				position: absolute;
				left: 0;

				button {
					@include color-set( --button-text, --base );
					padding: 0 grid-baseline(1);
					color: color-get( --button-text );
					transition: color 0.3s;

					&.-is-active {
						@include color-set( --button-text, --primary );
					}

					&:first-child {
						position: relative;
						margin-right: grid-baseline(1);

						&::after {
							content: '';
							position: absolute;
							top: -5%; right: grid-baseline(-1); bottom: -5%;
							width: 1px;
							background-color: white;
						}
					}

					&:last-child {
						margin-left: grid-baseline(1);
					}
				}
			}
		}

	&-logo {
		max-width: grid-baseline(23);
		margin-bottom: grid-baseline(5);
	}

	:global( .grid ) > #{ $s }_description {
		display: block;
	}

	&_description {
		column-gap: var( --grid-gutter-width );
		column-count: 2;
	}
}

/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@media (hover: hover) {
	.s-rarelabs {
		&-title_language {
			button:hover {
				color: color-get( --button-text, 0.4 );
			}
		}
	}
}

@include breakpoint-for( small ) using ( $bp ) {

	.s-rarelabs {

		&-logo {
			max-width: grid-baseline(18);
		}

		&-title {
			flex-direction: column;

			&_language {
				position: relative;
				order: 2;
				margin-bottom: grid-baseline(1);
			}
		}

		&_description {
			column-count: 1;
		}

		p {
			text-align: center;
		}
	}
}
