$env: production;
.dashboard-card {
  position: relative;
  width: 31%;
  height: auto;
  background: #141414;
  margin-bottom: 24px;
  border-radius: 10px;
  padding-bottom: 16px;

  &-images-container {
    position: relative;
    width: 100%;
    height: 220px;
    background-color: #313131;
    border-radius: 14px 14px 0px 0px;
  }
  &-overlaying-img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  &-inner-container {
    padding: 16px;
  }
  &-img {
    width: 100%;
    height: 100%;
  }

  &-paragraph {
    font-size: 12px;
    line-height: 150%;
    color: #ffffff;
    opacity: 0.5;
  }
  &-cta {
    text-transform: uppercase;
    margin-top: 8px;
    font-size: 12px;
    line-height: 140%;
    text-align: center;
    color: #b3fe00;
    border: 1px solid #b3fe00;
    border-radius: 31px;
    font-weight: 400;
    padding: 4px 10px;
  }
  @media (max-width: 800px) {
    width: 47%;
  }
  @media (max-width: 500px) {
    width: 100%;
  }
}
