
//
//	RAYC / Base / Display
//


.-flex-row-wrap {
	display: flex;
	flex-flow: row wrap;
}

.-flex-row {
	display: flex;
	flex-flow: row;
}

.-flex-column {
	display: flex;
	flex-flow: column;
}
