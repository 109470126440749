$env: production;
// wallet modal
.wallet-selections {
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 700px) {
        margin-top: 14px;
        margin-bottom: 20px;
    }
}
.wallet-modal-header{
    @media (max-width: 700px) {
        font-weight: 700;
        font-size: 40px;
        margin-bottom: 20px;
    }
}
.wallet-modal-info {
    &-desktop {
        @media (max-width: 700px) {
            display: none;
        }
    }
    &-mobile {
        display: none;
        @media (max-width: 700px) {
            display: block;
            text-align: center;
        }
    }
}
.wallet-modal-options {
    width: 225px;
    height: auto;
    &-title {
        font-weight: 400;
        font-size: 10px;
        line-height: 160%;
        text-align: center;
        text-transform: uppercase;
    }
    &-item {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 16px 0;
        width: 100%;
        padding: 17px 20px;
        cursor: pointer;
        transition: 0.2s ease-in-out forwards;

        &-icon {
            margin-right: 20px;
        }

        // wallet item text
        &-text {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            &-disabled {
                opacity: 0.7;
            }

            &-header {
                font-style: normal;
                font-weight: 700;
                font-size: 24px;
                line-height: 103.68%;
            }

            &-sub-header {
                // item sub-header such as coming soon
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 160%;
            }
        }

        // disabled classing
        &-disabled {
            opacity: 0.3;
        }

        // hover for item
        &:hover {
            border: 2px solid #c0c0c0;
            border-radius: 8px;
        }
        @media(max-width:700px){
            &:active {
                border: 2px solid #c0c0c0;
                border-radius: 8px;
            }
        }
    }
}
