$env: production;
.oddysey-album-wrapper {
    background-image: url("/images/apepeOdysseybg.webp"); // bg image has to be in wrapper for it to be included behind navbar & footer
}
.test {
    border: 1px solid red;
    margin-left: -40px !important;
}
.oddysey-album {
    &_header {
        // text gradient effect
        background: linear-gradient(271.23deg, #60ffcf 19.94%, #ffffff 63.65%, #5019d7 103.04%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

        // to push down in wrapper box
        transform: translateY(1px);

        // gradient border
        &_wrapper {
            background:
                linear-gradient(black, black) padding-box,
                linear-gradient(271.23deg, #60ffcf 19.94%, #ffffff 63.65%, #5019d7 103.04%) border-box;
            border-radius: 48px;
            border: 4px solid transparent;
            transform: translateX(20px);
            @media (max-width: 1400px) {
                transform: translateX(0px);
            }
        }
    }

    &_cards {
        display: grid; // this was not moved to tailwind as it had some weird layout issue so i kept it at scss
    }

    &_card {
        border: 1px solid #3f3f3f;
        border-radius: 12px;

        // same issue as above
        &_img {
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            background-color: #616161;
        }

        &_ribbon {
            position: absolute;
            top: -5%;
            right:-7.5%;
              @media (max-width: 700px) {
                top: 2%;
                right:0%;
            }
        }
    }
}
