$env: production;
.key-forge-anoited {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 60px;

  &-img-container {
    position: relative;
    @media (max-width: 900px) {
      margin-top: 50px;
    }
  }
  &-img {
    position: relative;
    z-index: 1;
    &-ribbon {
      position: absolute;
      z-index: 2;
    }

    @media (max-width: 600px) {
      width: 350px;
      height: 350px;
    }
    @media (max-width: 420px) {
      width: 300px;
      height: 300px;
    }
  }
  &-header-container {
    margin: 32px 0;
    max-width: 700px;
    @media (max-width: 800px) {
      max-width: 500px;
    }
    @media (max-width: 600px) {
      max-width: 400px;
      margin: 20px 0;
    }
    @media (max-width: 420px) {
      max-width: 320px;
    }
    @media (max-width: 320px) {
      max-width: 250px;
    }
  }

  &-header {
    font-size: 48px;
    text-transform: uppercase;
    display: inline-block;
    text-align: center;
    line-height: 110%;
    &-colored {
      margin-left: 10px;
    }
    @media (max-width: 800px) {
      font-size: 36px;
    }
    @media (max-width: 600px) {
      font-size: 28px;
    }
    @media (max-width: 420px) {
      font-size: 22px;
    }
  }

  &-cta {
    &-rarible {
      color: #b3fe00;
      font-size: 14px;
      font-weight: 700;
      text-transform: uppercase;
      margin-top: 20px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &-icon {
        margin-right: 9px;
        width: 21px;
        height: 21px;
      }
    }
  }
}
