$env: production;
@use 'styles/modules' as *;


//
//	RAYC / UI / Components / Flip Card
//


.c-flip-card {
	$c: &;

	&_container {
		display: flex;
		flex-direction: column;
		align-items: center; text-align: center;
		pointer-events: none;
		height: 100%;
	}

	&_title {
		margin-top: grid-baseline(4);
	}

	&_content {
		p {
			&:first-child {
				margin-top: grid-baseline(1);
			}
		}
	}


	&_img {
		max-width: grid-gutter(4);
		margin: 0 auto;
		border: 4px solid color-get( --primary );
		border-radius: 100%;
		box-shadow: 0px 0px 0px color-get( --primary ), 0px 0px 48px color-get( --primary, 0.88 );
		overflow: hidden;
		pointer-events: none;
	}

	&_button {
		display: none;
	}

	&_back {
		margin-top: auto;
	}
}


/**
 * 	Media Queries
 *	--------------------------------------------------
 */

/** Breakpoint:	Small ----------------------- */

@include breakpoint-for( small ) using ( $bp ) {

	.c-flip-card {
		$c: &;

		perspective: 1000px;
		margin-bottom: grid-baseline(4);
		min-height: grid-baseline(45);


		&_container {
			position: relative;
			pointer-events: auto;
			cursor: pointer;
			width: 100%; height: 100%;
			padding: 0;
			text-align: center;
			transition: transform 0.8s;
			transform-style: preserve-3d;
		}

		&_title {
			margin-top: grid-baseline(2);
		}

		&_content {

			p {
				font-size: type-size-responsive( 12, 16 );
				line-height: 145%;

				&:first-child {
					margin-top: 0;
				}
			}
		}

		&_front, &_back {
			width: 100%; height: 100%;
			perspective: 0;
			transform: translate3d(0,0,0);
			visibility:visible;
			backface-visibility: hidden;
			border: 1px solid color-get( grey-60 );
			border-radius: 8px;
			padding: grid-baseline(4) grid-gutter(3.5);
			box-sizing: border-box;
			background: color-get( grey-80 );
		}

		&_front {
			position: absolute;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}

		&_back {
			display: flex;
			flex-direction: column;
			justify-content: center;
			transform: rotateY(-180deg);
		}

		&_img {
			max-width: grid-baseline(20);
			border: none;
			box-shadow: inset -4px -4px 4px color-get( white, 0.12 ), inset 4px 4px 8px color-get( black, 0.6 );
			filter: drop-shadow(4px 4px 8px color-get(black, 0.75)) drop-shadow(-4px -4px 8px color-get( white, 0.12 ));
		}

		&_button {
			display: inline-block;
		}

		// States

		&.-is-flipped {
			#{ $c }_container  {
				transform: rotateY(-180deg);
			}
		}
	}
}
