$env: production;


.key-forge-key-overview {

  &-item { 

    &-balance-info {
      backdrop-filter: blur(2px);

      &-header {
        
        &-neon {
          /* Gradient */
          background: linear-gradient(266.74deg, #b3fe00 45.07%, #00f644 97.3%);
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
        }

      }
  

  
      &-count-manager {
        &-info {
          &-value {
            text-shadow: -2px -2px 0 #76ffa2, 2px -2px 0 #76ffa2,
              -2px 2px 0 #76ffa2, 2px 2px 0 #76ffa2;
          }
        }
      }
    }

    &-header {
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    //  end key forge item
  }
}
//  end key forge overview
