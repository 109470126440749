$env: production;
@use 'src/styles/base/mixins.scss';

.community-tools {

  &-paragraph {
    color: #c0c0c0;
    max-width: 727px;

    span {
      color: #b3fe00;
    }
  }

  &-cards {
   
    &-item {
      width: 31%;
      @media (max-width: 800px) {
        width: 47.5%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }

}
