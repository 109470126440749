$env: production;
@use 'src/styles/base/mixins.scss';

.community-projects {
  &-paragraph {
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #c0c0c0;
    max-width: 727px;
    margin: 32px 0;
    span {
      color: #b3fe00;
    }
  }
  &-cards {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &-item {
      width: 31%;
      @media (max-width: 800px) {
        width: 47.5%;
      }
      @media (max-width: 500px) {
        width: 100%;
      }
    }
  }
}
