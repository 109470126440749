$env: production;
@use 'src/styles/base/mixins.scss';
 
.dashboard {
  // dashboard start
  box-sizing: border-box;
  background-color: black;
  width: 100%;
  height: auto;
  display: flex;
  padding: 30px 110px;

  //   tabs start
  &-tabs {
    box-sizing: border-box;
    width: 20%;
    height: 955px;
    margin-right: 30px;
    background: linear-gradient(
      180deg,
      #141414 45.44%,
      rgba(20, 20, 20, 0) 100%
    );
    border-radius: 14px;
    padding: 30px;

    // item start
    &-item {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 36px 0;
      cursor: pointer;

      //   icon start
      &-icon {
        z-index: 2;
        margin-bottom: 12px;
        svg {
          fill: #c0c0c0;
        }
        @media (max-width: 900px) {
          margin-bottom: 6px;
        }
      }
      //   icon end

      //    tab  item text start
      &-text {
        z-index: 2;
        font-weight: 700;
        font-size: 16px;
        text-align: center;
        text-transform: uppercase;
        opacity: 0.5;
        color: #c0c0c0;
        @media (max-width: 600px) {
          font-size: 12px;
        }
        @media (max-width: 350px) {
          flex-direction: column;
          font-size: 10px;
        }
      }
      //    tab  item text start

      // item selection and hover states start
      &-selected &-text {
        opacity: 1;
        @include mixins.neonColoredText;
      }
      &-selected &-icon svg {
        fill: #b3fe00;
      }

      &-glow{
        z-index:1;
        background: linear-gradient(266.74deg, #b3fe00 45.07%, #00f644 97.3%);
        width:50%;
        height:50%;
        position:absolute;
        top:50%;
        left:50%;
        transform:translate(-50%,-50%);
        border-radius:50%;
        filter: blur(24px);
        visibility: hidden;
      }

      &:hover &-glow,&-selected &-glow{
        visibility: visible;
      }

      &:hover &-text {
        opacity: 1;
        @include mixins.neonColoredText;
      }
      &:hover &-icon svg {
        fill: #b3fe00;
      }
      // item selection and hover states start

      // item media queries start
      @media (max-width: 900px) {
        margin: 0;
        justify-content: space-around;
        align-items: center;
        width: 22%;
      }
      // item media queries end
    }
    // item end

    //   tabs media queries start
    @media (max-width: 900px) {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: space-between;
      padding: 15px;
    }
    @media (max-width: 700px) {
      padding: 14px 5px;
    }
    //   tabs media queries end
  }
  //   tabs end

  //   tab content start
  &-tab-content {
    width: 80%;
    height: auto;

    //   tab content media queries start
    @media (max-width: 900px) {
      width: 100%;
      margin-top: 35px;
    }
    //   tab content media queries end
  }
  //   tab content end

  //   dashboard media queries start
  @media (max-width: 900px) {
    flex-direction: column;
    padding: 30px 50px;
  }
  @media (max-width: 700px) {
    flex-direction: column;
    padding: 30px 22px;
  }
  @media (max-width: 350px) {
    flex-direction: column;
    padding: 30px 12px;
  }
  //   dashboard media queries end

  //   dashboard end
}
